import AuthRequestHelper from "./AuthRequestHelper"

class AdminServicesHelper{

    //set the trial time in seconds
    async setTrialTime(id, time){
        try{
            const requestUrl="changesetting/"+id
            const queryparams={
                "keytype":"freeTrialTime",
                "value": time
            }           
            const SettingsParam= await AuthRequestHelper.request("put",requestUrl,queryparams)    
            return SettingsParam  
        }
        catch(error){
            console.log(error)
            return error
            //throw error
        }
    } 

    //get the trial time in seconds
    async getTrialTime(id){
        try{
            const requestUrl="changesetting/"+id
            const queryparams={
                "keytype":"freeTrialTime"
            }           
            const SettingsParam= await AuthRequestHelper.request("post",requestUrl,queryparams)    
            return SettingsParam  
        }
        catch(error){
            console.log(error)
            return error
            //throw error
        }
    } 


    //set the trial time in seconds
    async setMemberState(id, email,mem_type){
        try{
            const requestUrl="changememstate/"+id
            const queryparams={
                "email":email,
                "mem_type": mem_type
            }           
            const memStateResult= await AuthRequestHelper.request("put",requestUrl,queryparams)    
            return memStateResult  
        }
        catch(error){
            console.log(error)
            return error
            //throw error
        }
    } 


}


export default new AdminServicesHelper()
