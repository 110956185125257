const config = {
    env: 'production', // change into dev to see console.log, production to publish
    localAuth: {
        // apiServiceUrl: 'http://localhost:5000/api/'
        apiServiceUrl: process.env.REACT_APP_API_SERVER
    },
    version: 'v1.5.3'
}

// console.log(process.env.REACT_APP_API_SERVER)

export default config
