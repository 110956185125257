const zh = {
    loading: '頁面載入中，請稍等...',
    menu: {
        title: '設定選單',
        login: '免費註冊/登入會員',
        subscribe: '付費會員',
        reserve: '預約算命',
        setting: '會員專區（會員專屬）',
        logout: '登出',
        backup: '備份命例（會員專屬）',
        loginFirst: '不巧，這個是會員專屬的功能喔。請您先註冊或登入，我們期待為您提供最佳化的命理服務體驗。',
        memberOnly: '會員獨享功能',
        yesLogin: '好，我要馬上註冊/登入',
        version: '版本：',
        admin: '管理員介面'
    },
    index: {
        doneUpdated: '更新已完成，正在重新啟動您的app',
        updateInstalled: '新版本已安裝',
        ok: '確認',
        installingUpdate: '偵測到新版本，正在為您更新',
        restartApp: '新版本已經更新完成，請重新啟動您的app'
    },
    auth: {
        forgotPassPage: {
            backToLogin: '回到登入頁',
            enterEmail: '請輸入Email',
            getTempPass: '獲取暫時密碼',
            loading: '寄出暫時密碼中...',
            emailSent: '暫時密碼已寄到您信箱，您可登入後在設定裡重設密碼。',
            failed: '系統無法寄出暫時密碼，請檢查您的信箱是否輸入錯誤，或者您的網路連線狀況。'
        },
        registerPage: {
            existingMember: '已經有帳號了？',
            login: '馬上登入',
            enterEmail: '請輸入Email（帳號）',
            enterPass: '請輸入密碼',
            enterConfirmPass: '請再確認密碼',
            enterNickname: '請輸入您的暱稱',
            enterGender: '性別',
            enterBirthday: '請輸入您的國曆生辰八字（互動解盤用）',
            useSolar: '使用真太陽時',
            forgotEmail: '您忘記輸入Email了嗎？',
            emailFormat: '請確認您的email格式',
            forgotPass: '請輸入您的密碼',
            confirmPass: '請再確認一次您的密碼',
            passMismatch: '您兩次輸入的密碼不同喔',
            astrologist: '命理師',
            usernameExist: '這個帳號已經註冊過了喔，如果這是您的帳號，請按下方的登入按鈕登入',
            unknownError: '系統在執行註冊時出錯了，請您稍後再試',
            registered: '恭喜您！註冊成功了。歡迎使用紫微攻略。',
            loading: '請稍候...',
            registerSubmit: '註冊',
            nickeNamePlaceholder: '請輸入命主暱稱',
            enterGender: '性別',
            genderPlaceholder: '',
            dob: '生日',
            useSolar: '使用真太陽時',
            passwordEmpty: '重設密碼時，密碼欄位不可為空白',
            enterSolar: '輸入國曆',
            enterLounar: '輸入農曆',
            dispSolar: '(將顯為示國曆)',
            cancel: '取消',
            dobPlaceholder: "例：1990年1月1日 10:00",
            category: '類別',
            categoryPlaceholder: '預設為其他',
            confirmEdit: '確認修改',
            confirmAdd: '確認增加',
            defaultNickname: '命理師'
        },
        loginPage: {
            loggedIn: '您已經登錄，畫面將在三秒後轉回命盤頁面',
            notYetMember: '還不是會員嗎？',
            clickRegister: '點我免費註冊',
            email: '請輸入Email',
            password: '請輸入密碼',
            forgotPass: '忘記密碼？',
            loading: '登入中...',
            loginFailed: '登入失敗了，請檢查您的Email或密碼',
            login: '登入'
        },
        'forgotPass': '取回密碼',
        'register': '免費註冊會員',
        'login': '登入'
    },
    chart: {
        title: '',
        prev: '上一頁',
        loading: '正在下載命盤資料中...',
        solar: '陽曆：',
        lunar: '農曆：',
        mingZhu: '命主:',
        shenZhu: '身主:',
        yin: '陰',
        yang: '陽',
        male: '男',
        female: '女',
        natalExplain: '本命盤解說',
        failToLoad: '系統目前無法下載命盤資料，請檢查您的網路連線。',
        ifConnected: '若是您已連上網路卻依舊無法使用，請向我們回報錯誤。紫微攻略小工程師以新鮮的肝發誓會盡快修理好系統！',
        report: '通知紫微攻略小工程師',
        oops: '哎呀，系統出錯了...',
        notPortrait: '看來您不小心旋轉了畫面喔',
        pleaseRotate: '在手機上為了達到最好的看盤效果，請您使用直式的排盤介面，紫微攻略小工程師感謝您的配合。'
    },
    profile: {
        title: '命盤資料庫',
        categories: {
            'self': '我',
            'family': '家人',
            'friend': '朋友',
            'client': '客戶',
            'celebrity': '名人',
            'others': '其他'
        },
        attention: '注意',
        confirm: '您確定要刪除這個命盤？',
        ok: '確定刪除',
        nickname: '暱稱',
        nickeNamePlaceholder: '請輸入命主暱稱',
        enterGender: '性別',
        genderPlaceholder: '',
        dob: '生日',
        useSolar: '使用真太陽時',
        passwordEmpty: '重設密碼時，密碼欄位不可為空白',
        enterSolar: '輸入國曆',
        enterLounar: '輸入農曆',
        dispSolar: '(將顯為示國曆)',
        cancel: '取消',
        dobPlaceholder: "例：1990年1月1日 10:00",
        category: '類別',
        categoryPlaceholder: '預設為其他',
        confirmEdit: '確認修改',
        confirmAdd: '確認增加',
        defaultNickname: '隨機起盤'
    },
    setting:
    {
        title: '會員專區',
        welcome: '會員專區可以編輯您的會員資料與檢視您的偏好設定',
        nickname:'暱稱',
        dob: '生日',
        confirm: '確認編輯',
        updateSuccess: '編輯成功',
        updateError: '糟糕，編輯資料失敗了。有可能是系統出錯了，請您過一陣子以後再嘗試。',
        resetpass: '輸入新的密碼',
        clickResetPass: '點我重設密碼',
        enterGender: '性別',
        useSolar: '使用真太陽時',
        passwordEmpty: '重設密碼時，密碼欄位不可為空白',
        enterSolar: '輸入國曆',
        enterLounar: '輸入農曆',
        dispSolar: '(將顯為示國曆)',
        cancel: '取消',
        adminPage: '管理員介面',
        promotionPeriodToggle: '免費試用講義促銷期',
        enable: '啟用促銷期',
        setPromotion: '輸入想要的促銷期日數，然後按右上方的開關開始促銷期',
        newUser: '促銷日數',
        months: '月',
        weeks: '週',
        days: '日',
        promoEnabled: '已啟用[day]日促銷期',
        promotion: '免費試用命盤解說講義',
        addSubscription: '贈送一年免費會員資格',
        setSubscription: '輸入想要贈送一年免費會員資格的使用者電子信箱，然後按下確定按鈕',
        removeSubscription: '移除付費會員資格',
        setRemoveSubscription: '輸入想要移除會員資格的使用者電子信箱，然後按下確定按鈕',
        userEmail: '電子信箱',
        userEmailExample: 'user@example.com',
        add: '增加付費會員',
        removeTheUer: '移除付費會員',
        alert: '請確認',
        confirmAddingEmails: '確定要贈送使用者一年免費的付費會員資格嗎？',
        confirmRemovingEmails: '確定要移除使用者的付費會員資格嗎？',
        ok: '確定',
        cancel: '取消',
        errorHappenedWhileAddingSubscription: '系統發生問題，無法贈送VIP會員資格給以下會員：[list]。請您仔細檢查輸入的email是否有錯。',
        added: '已成功增加[no]位VIP會員',
        removed: '已成功移除[no]位會員的VIP資格',
        errorHappenedWhileRemovingSubscription: '系統發生問題，無法移除以下會員的VIP會員資格：[list]。請您仔細檢查輸入的email是否有錯。'
    },
    reserve:
    {
        title: '預約算命'
    },
    backup:
    {
        title: '備份命例到雲端',
        upload: '上傳命例到雲端',
        download: '從雲端下載命例',
        uploadSuccess: '上傳命例成功',
        uploadError: '糟糕，命例上傳失敗了。有可能是系統出錯了，請您過一陣子以後再嘗試。',
        downloadSuccess: '下載命例同步成功',
        downloadError: '糟糕，下載命例同步失敗了。請確認您是否有命例存在雲端。',
    },
    textbook: {
        title: '紫微攻略',
        loading: '正在製作專屬紫微攻略...',
        emptyPalace: '空宮借對宮主星來參考',
        backToTop: '回頂端',
        loginFirst: '請先註冊或登入會員再使用此功能。',
        subscribeFirst: '紫微攻略命盤解說屬於付費會員專屬的功能，請先註冊為付費會員才能觀看。',
        register: '知道了，我馬上登入',
        subscribe: '好的，我想當付費會員',
        textbookFailedToLoad: '伺服器目前無法取得紫微攻略的內容，我們深感抱歉，工程師們正在努力搶修中，請您過一陣子再嘗試。'
    },
    subscribe:
    {
        title: '付費會員',
        comingSoon: '即將開放搶購',
        bePatient: '感謝您對紫微攻略付費會員的興趣，我們即將要開放購買囉，請再等一下下，千萬不要錯過。',
        ok: '我瞭解了',
        becomeMember: '馬上成為付費會員',
        price: '特價：',
        currency: '台幣',
        period: '起訖：',
        to: '到',
        joinTheSubscription: '購買紫微攻略付費會員',
        yearSubscription: '紫微攻略付費會員',
        trialSubscription: '紫微攻略促銷期會員',
        paid: '付費完成',
        subtitle: '感謝您選擇成為紫微攻略的付費會員',
        description: '您的會員期間由[begin]至[end]，為期一年，祝您使用愉快。'
    },
    chat: 
    {
        oops: '小助手是付費會員專屬',
        payFirst: '還在猶豫什麼？我們竭誠歡迎您加入紫微攻略的付費會員，小助手將24小時不停歇為您提供最新的流年運勢。',
        toPayment: '好，我要成為付費會員'
    }
}

export default zh