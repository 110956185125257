import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router"
import withPreloader from '../withPreloader'
import Header from '../../assests/Header'
import { 
    IonContent,
    IonPage,
    IonMenuButton,
    IonButtons,
    IonItem,
    IonLabel,
    IonItemGroup,
    IonItemDivider,
    IonList,
    IonToggle,
    IonButton,
    IonRow,
    IonCol,
    IonSpinner,
    IonInput,
    IonIcon,
    IonAlert,
    IonTitle,
    IonListHeader
} from '@ionic/react'
import { closeCircle, addCircle, trash } from 'ionicons/icons'

import lang from '../../lang/init'
import AuthenticationHelper from '../../helpers/AuthenticationHelper'
import GeneralHelper from '../../helpers/GeneralHelper'
import AdminServicesHelper from '../../helpers/AdminServicesHelper'

class AdminPage extends React.Component {
    
    constructor(props)
    {
        super(props)
        this.state = {
            promoEnabled: false,
            loading: false,
            promotionPeriod: 0,
            emails: [''],
            removeEmails: [''],
            showAlert: false,
            showRemoveAlert: false,
            feedback: '',
            removeFeedback: ''
        }
        this.togglePromotion = this.togglePromotion.bind(this)
        this.setPromotionPeriodValue = this.setPromotionPeriodValue.bind(this)
        this.addEmail = this.addEmail.bind(this)
        this.deleteEmail = this.deleteEmail.bind(this)
        this.confirmAddEmails = this.confirmAddEmails.bind(this)
        this.confirmRemoveEmails = this.confirmRemoveEmails.bind(this)
    }

    async componentDidMount()
    {
        try
        {
            const trial = await AdminServicesHelper.getTrialTime(AuthenticationHelper.uuid)
            if(trial?.data?.value && parseInt(trial?.data?.value, 10) > 0)
            {
                const convertedPeriod = parseInt(trial?.data?.value, 10) / (24 * 60 * 60)
                this.setState({
                    promoEnabled: true,
                    promotionPeriod: convertedPeriod
                })
            }
            // console.log(trial)
        }
        catch(e)
        {
            
        }
    }

    togglePromotion = async (checked = false) =>
    {
        this.setState({
            promoEnabled: checked
        })
        if(checked)
        {
            // console.log(this.state.promotionPeriod)
            // convert the period to seconds to use the helper function
            const convertedPeriod = parseInt(this.state.promotionPeriod, 10) * 24 * 60 * 60
            //set the trial period
            try
            {
                let response= await AdminServicesHelper.setTrialTime(AuthenticationHelper.uuid, convertedPeriod.toString()) 
                if(response.value)
                {
                    return
                }
            }
            catch(e)
            {
                //TODO: error control
                this.setState({
                    promoEnabled: false
                })
            }
            return
        }
        try
        {
            let response= await AdminServicesHelper.setTrialTime(AuthenticationHelper.uuid, "0") 
            return 
        }
        catch(e)
        {
            //TODO: error control
            this.setState({
                promoEnabled: true
            })
        }
        return
    }
    // period is in days
    setPromotionPeriodValue = (period = 0) => {

        this.setState({
            promotionPeriod: period
        })
    }
    // set the email to be added to the subscriber list
    setEmail = (v = '', idx = 0, list='add') => {
        switch(list)
        {
            case 'remove':
                let newRemoveEmails = [...this.state.removeEmails]
                newRemoveEmails[idx] = v
                return this.setState({
                    removeEmails: newRemoveEmails
                })
            case 'add':
            default:
                let newEmails = [...this.state.emails]
                newEmails[idx] = v
                return this.setState({
                    emails: newEmails
                })
        }

    }

    addEmail = (list = 'add') => {
        switch(list)
        {
            case 'remove':
                let newRemoveEmails = [...this.state.removeEmails]
                newRemoveEmails.push('')
                return this.setState({
                    removeEmails: newRemoveEmails
                })
            case 'add':
            default:
                let newEmails = [...this.state.emails]
                newEmails.push('')
                return this.setState({
                    emails: newEmails
                })
        }

    }

    deleteEmail = (idx = 0, list = 'add') => {
        switch(list)
        {
            case 'remove':
                let newRemoveEmails = [...this.state.removeEmails]
                newRemoveEmails.splice(idx, 1)
                if(newRemoveEmails.length <= 0)
                {
                    newRemoveEmails.push('')
                }
                return this.setState({
                    removeEmails: newRemoveEmails
                })
            case 'add':
            default:
                let newEmails = [...this.state.emails]
                newEmails.splice(idx, 1)
                if(newEmails.length <= 0)
                {
                    newEmails.push('')
                }
                return this.setState({
                    emails: newEmails
                })
        }
    }

    confirmAddEmails = async (displayAlert = true) => {
        // console.log('add')
        // ask user to confirm first
        if(displayAlert)
        {
            return this.setState({
                showAlert: true
            })
        }
        // if user has confirmed
        let emailList = [...this.state.emails]
        const { preferences } = this.props
        const dict = lang(preferences.lang)['setting']
        let successList = [], failedList = [...emailList]
        const loop = await GeneralHelper.asyncForEach(emailList, async (mail) => {
            try
            {
                let responseMemstate=await AdminServicesHelper.setMemberState(AuthenticationHelper.uuid, mail, "1")
                // console.log(responseMemstate)
                if(responseMemstate.status === 'success')
                {
                    successList.push(mail)
                    const idx = failedList.indexOf(mail)
                    failedList.splice(idx, 1)
                }
            }
            catch(e)
            {
                // this.setState({
                //     feedback: dict['failedToAdd']
                // })
                // console.log(e)
                //TODO: error control
            }
        })
        // await emailList.forEach(async (mail) => {
        //     try
        //     {
        //         let responseMemstate=await AdminServicesHelper.setMemberState(AuthenticationHelper.uuid, mail, "1")
        //         console.log(responseMemstate)
        //         successList.push(mail)
        //         const idx = failedList.indexOf(mail)
        //         failedList.splice(idx, 1)
        //     }
        //     catch(e)
        //     {
        //         // this.setState({
        //         //     feedback: dict['failedToAdd']
        //         // })
        //         // console.log(e)
        //         //TODO: error control
        //     }
        // })
        // console.log(failedList)
        if(failedList.length > 0)
        {
            this.setState({
                emails: failedList,
                feedback: `${dict['added'].replace('[no]', successList.length)}。${dict['errorHappenedWhileAddingSubscription'].replace('[list]', failedList.join(', '))}`
            })
            return
        }
        // console.log(successList)
        this.setState({
            emails: [''],
            feedback: dict['added'].replace('[no]', successList.length)
        })
    }

    confirmRemoveEmails = async (displayAlert = true) => {
        // console.log('remove')
        // ask user to confirm first
        if(displayAlert)
        {
            return this.setState({
                showRemoveAlert: true
            })
        }
        // if user has confirmed
        let emailList = [...this.state.removeEmails]
        const { preferences } = this.props
        const dict = lang(preferences.lang)['setting']
        let successList = [], failedList = [...emailList]
        const loop = await GeneralHelper.asyncForEach(emailList, async (mail) => {
            try
            {
                let responseMemstate=await AdminServicesHelper.setMemberState(AuthenticationHelper.uuid, mail, "0")
                // console.log(responseMemstate)
                if(responseMemstate.status === 'success')
                {
                    successList.push(mail)
                    const idx = failedList.indexOf(mail)
                    failedList.splice(idx, 1)
                }
            }
            catch(e)
            {
                // this.setState({
                //     feedback: dict['failedToAdd']
                // })
                // console.log(e)
                //TODO: error control
            }
        })
        // await emailList.forEach(async (mail) => {
        //     try
        //     {
        //         let responseMemstate=await AdminServicesHelper.setMemberState(AuthenticationHelper.uuid, mail, "1")
        //         console.log(responseMemstate)
        //         successList.push(mail)
        //         const idx = failedList.indexOf(mail)
        //         failedList.splice(idx, 1)
        //     }
        //     catch(e)
        //     {
        //         // this.setState({
        //         //     feedback: dict['failedToAdd']
        //         // })
        //         // console.log(e)
        //         //TODO: error control
        //     }
        // })
        // console.log(failedList)
        if(failedList.length > 0)
        {
            this.setState({
                removeEmails: failedList,
                removeFeedback: `${dict['removed'].replace('[no]', successList.length)}。${dict['errorHappenedWhileRemovingSubscription'].replace('[list]', failedList.join(', '))}`
            })
            return
        }
        // console.log(successList)
        this.setState({
            removeEmails: [''],
            removeFeedback: dict['removed'].replace('[no]', successList.length)
        })
    }


    render()
    {
        const { preferences } = this.props
        const { emails, removeEmails } = this.state
        const dict = lang(preferences.lang)['setting']
        let emailsInput = [], removeEmailsInput = []
        if(emails.length > 0)
        {
            emails.map((email, idx) => {
                emailsInput.push(<IonItem key={`email-input-${idx}`} lines="none">
                    <IonLabel slot="start">{`${dict['userEmail']}`}</IonLabel>
                    <IonInput value={email} placeholder={dict['userEmailExample']} type='text' onIonChange={e => this.setEmail(e.detail.value, idx)}/>
                    
                    <IonIcon icon={addCircle} slot="end" onClick={e => this.addEmail()} />
                    <IonIcon icon={trash} slot="end" onClick={e => this.deleteEmail(idx)} />
                </IonItem>)
            })
        }
        if(removeEmails.length > 0)
        {
            removeEmails.map((email, idx) => {
                removeEmailsInput.push(<IonItem key={`remove-email-input-${idx}`} lines="none">
                    <IonLabel slot="start">{`${dict['userEmail']}`}</IonLabel>
                    <IonInput value={email} placeholder={dict['userEmailExample']} type='text' onIonChange={e => this.setEmail(e.detail.value, idx, 'remove')}/>
                    
                    <IonIcon icon={addCircle} slot="end" onClick={e => this.addEmail('remove')} />
                    <IonIcon icon={trash} slot="end" onClick={e => this.deleteEmail(idx, 'remove')} />
                </IonItem>)
            })
        }
        return (<IonPage>
            <Header title={dict['adminPage']} 
                withSearchBar={false} 
                leftComponent={<IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>} 
            />
            <IonContent>
            {/** This is the alert for adding VIP member */}
            <IonAlert
                isOpen={this.state.showAlert}
                onDidDismiss={() => this.setState({ showAlert: !this.state.showAlert })}
                cssClass='my-custom-class'
                header={dict['alert']}
                // subHeader={'Subtitle'}
                message={dict['confirmAddingEmails']}
                buttons={[
                    {
                      text: dict['cancel'],
                      role: 'cancel',
                      cssClass: 'secondary'
                    },
                    {
                      text: dict['ok'],
                      handler: () => {
                        this.confirmAddEmails(false)
                        return
                      }
                    }
                  ]}
            />
            {/** This is the alert for removing VIP member */}
            <IonAlert
                isOpen={this.state.showRemoveAlert}
                onDidDismiss={() => this.setState({ showRemoveAlert: !this.state.showRemoveAlert })}
                cssClass='my-custom-class'
                header={dict['alert']}
                // subHeader={'Subtitle'}
                message={dict['confirmRemovingEmails']}
                buttons={[
                    {
                      text: dict['cancel'],
                      role: 'cancel',
                      cssClass: 'secondary'
                    },
                    {
                      text: dict['ok'],
                      handler: () => {
                        this.confirmRemoveEmails(false)
                        return
                      }
                    }
                  ]}
            />

            <IonList>
            {/** Toggle the promotional period by setting the date */}
            <IonItemGroup>
                {<IonItem>
                    <h3>{dict['promotionPeriodToggle']}</h3>
                </IonItem>}
                <IonItem lines="none">
                <IonLabel>{dict['enable']}</IonLabel>
                <IonToggle color="zdark" checked={this.state.promoEnabled} onIonChange={e => this.togglePromotion(e.detail.checked)} />
                </IonItem>


                {this.state.promoEnabled ? 
                    <IonItem lines="none">
                    {`${dict['promoEnabled'].replace('[day]', this.state.promotionPeriod)}`}
                    </IonItem>
                    :
                    <React.Fragment>
                    <IonItem lines="none">
                    {`${dict['setPromotion']}`}
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel>{`${dict['newUser']}`}</IonLabel>
                        <IonInput value={this.state.promotionPeriod} placeholder={`${this.state.promotionPeriod}${dict['days']}`} type='number' onIonChange={e => this.setPromotionPeriodValue(e.detail.value)}/>
                    </IonItem>
                    </React.Fragment>
                }

            </IonItemGroup>
            {/** Change the member state of a user to VIP by email */}
            <IonItemGroup>
            {<IonItem>
                <h3>{dict['addSubscription']}</h3>
            </IonItem>}
            <IonItem lines="none" style={{ marginTop: '10px' }}>
            {`${dict['setSubscription']}`}
            </IonItem>

            {emailsInput}
            {/*<IonItem lines="none">
            <IonLabel slot="start">{`${dict['userEmail']}`}</IonLabel>
            <IonInput style={{ textAlign: 'center' }} value={this.state.email} placeholder={dict['userEmailExample']} type='text' onIonChange={e => this.setEmail(e.detail.value)}/>
            
            <IonIcon icon={addCircle} slot="end" />
            <IonIcon icon={trash} slot="end" />

            </IonItem>*/}
            
            <IonRow>
            <IonCol>
            <IonButton color="zdark" expand="block" onClick={() => this.confirmAddEmails(true)}>{this.state.loading ? <IonSpinner name='lines' /> :dict['add']}</IonButton>
            </IonCol>
            </IonRow>
            {this.state.feedback !== '' ? 
                <IonItem>
                {this.state.feedback}
                </IonItem>
            :null}
            </IonItemGroup>

            {/** Remove a VIP state from a user by email */}
            <IonItemGroup>
            {<IonItem>
                <h3>{dict['removeSubscription']}</h3>
            </IonItem>}
            <IonItem lines="none" style={{ marginTop: '10px' }}>
            {`${dict['setRemoveSubscription']}`}
            </IonItem>

            {removeEmailsInput}
            {/*<IonItem lines="none">
            <IonLabel slot="start">{`${dict['userEmail']}`}</IonLabel>
            <IonInput style={{ textAlign: 'center' }} value={this.state.email} placeholder={dict['userEmailExample']} type='text' onIonChange={e => this.setEmail(e.detail.value)}/>
            
            <IonIcon icon={addCircle} slot="end" />
            <IonIcon icon={trash} slot="end" />

            </IonItem>*/}
            
            <IonRow>
            <IonCol>
            <IonButton color="zdark" expand="block" onClick={() => this.confirmRemoveEmails(true)}>{this.state.loading ? <IonSpinner name='lines' /> :dict['removeTheUer']}</IonButton>
            </IonCol>
            </IonRow>
            {this.state.removeFeedback !== '' ? 
                <IonItem>
                {this.state.removeFeedback}
                </IonItem>
            :null}
            </IonItemGroup>


            </IonList>
            </IonContent>
        </IonPage>)
    }
}


export default withPreloader(withRouter(AdminPage))