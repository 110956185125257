import React, { Component } from 'react'
import { IonContent, IonLoading } from '@ionic/react'
import { Plugins } from '@capacitor/core'
import AuthenticationHelper from '../helpers/AuthenticationHelper'
import init from '../config/init'
import lang from '../lang/init'
import PaymentHelper from '../helpers/PaymentHelper'
import UserProfileHelper from '../helpers/UserProfileHelper'

const { Storage } = Plugins

// const dict = lang

function withPreloader(WrappedComponent) {
    return class PreloadedComponent extends Component {
        constructor(props)
        {
            super(props)
            this.state = {
                preloaded: false,
                dict: lang(init.defaultLang)
            }
        }

        async componentDidMount()
        {
            const preferences = await Storage.get({ key: init.storageKey.preferences })
            if(preferences.value === null)
            {
                // console.log('preference is not set')
                let v = {}
                v['lang'] = init.defaultLang
                const set = await Storage.set({
                    key: init.storageKey.preferences,
                    value: JSON.stringify(v)
                })
            }
            else if(typeof preferences.value === 'string' && !preferences.value.hasOwnProperty('lang'))
            {
                // console.log('preference does not have default lang')
                let v = JSON.parse(preferences.value)
                v['lang'] = init.defaultLang
                const set = await Storage.set({
                    key: init.storageKey.preferences,
                    value: JSON.stringify(v)
                })
            }
            // console.log(preferences)
            let updatePreferences = await Storage.get({ key: init.storageKey.preferences })
            updatePreferences = JSON.parse(updatePreferences.value)
            // const uuid = AuthenticationHelper.uuid
            // console.log(AuthenticationHelper.calims)
            // const loggedIn = AuthenticationHelper.hasAuthenticated()
            const loggedIn = await AuthenticationHelper.checkTokenExpiry()
            // console.log(`check logged in ${loggedIn}`)
            let isAdmin = false
            if(loggedIn)
            {
                const getRole = await UserProfileHelper.getRoleById(AuthenticationHelper.uuid)
                // console.log(getRole)
                if(getRole?.data?.role === 'admin')
                {
                    isAdmin = true
                }
            }

            // console.log(uuid)
            // console.log(`is logged in ${loggedIn}`)
            // console.log(update)
            this.setState({
                preloaded: true,
                preferences: updatePreferences,
                dict: lang(updatePreferences.lang),
                isLoggedIn: loggedIn,
                isAdmin: isAdmin
            })
        }

        render()
        {
            if(!this.state.preloaded)
            {
                return (
                    <IonContent>
                      <IonLoading
                        cssClass='my-custom-class'
                        isOpen={!this.state.preloaded}
                        message={this.state.dict['loading']}
                        duration={5000}
                      />
                    </IonContent>
                  )
            }
            return <WrappedComponent {...this.props} {...this.state} />
        }
    }
}

export default withPreloader