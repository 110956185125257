import React from 'react'
import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonRippleEffect, IonThumbnail, IonImg } from '@ionic/react'
import { menuController } from '@ionic/core'
import './Index.css'
import { withRouter } from "react-router"
import init from '../config/init'

const TabBar = props => {
    // console.log(props.location.pathname)
    return (
      <IonTabs>
        {props.children}
        <IonTabBar slot="bottom">
          <IonTabButton tab="命盤" onClick={() => {
            menuController.close()
            if(props.location.pathname === init.router.profile.url) return
            props.history.push(init.router.profile.url)
          }}>
            <IonThumbnail className='tab-button left-tab-button' style={{ width: '30px', height: '30px' }}>
              <IonImg src={props.location.pathname === init.router.profile.url ? require('../images/Tab/Calendar-selected.svg') : require('../images/Tab/Calendar-unselected.svg')} />
            </IonThumbnail>
            {/*<IonIcon className='tab-button' size="large" src={props.location.pathname === '/profiles' ? require('../images/Tab/Calendar-selected.svg') : require('../images/Tab/Calendar-unselected.svg')} />*/}
            <IonLabel>命盤</IonLabel>
            <IonRippleEffect></IonRippleEffect>
          </IonTabButton>
    
          <IonTabButton tab="互動解盤" onClick={() => {
            menuController.close()
            if(props.location.pathname === init.router.chat.url) return
            props.history.push(init.router.chat.url)
          }}>
            <IonThumbnail className='tab-button right-tab-button' style={{ width: '30px', height: '30px' }}>
              <IonImg src={props.location.pathname === init.router.chat.url ? require('../images/Tab/Chat-selected.svg') : require('../images/Tab/Chat-unselected.svg')} />
            </IonThumbnail>
            {/*<IonIcon className='tab-button' size="large" src={props.location.pathname === '/chat' ? require('../images/Tab/Chat-selected.svg') : require('../images/Tab/Chat-unselected.svg')} />*/}
            <IonLabel>互動解盤</IonLabel>
            <IonRippleEffect></IonRippleEffect>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    )
}

export default withRouter(TabBar)