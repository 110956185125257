import React, { useEffect, useState, useRef,useCallback  } from 'react'
import { withRouter } from 'react-router'
import { withIonLifeCycle, IonImg, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonLoading, IonIcon, IonGrid, IonRow, IonCol, useIonViewDidEnter } from '@ionic/react'
import lang from '../../lang/init'
import withPreloader from '../withPreloader'
import Accordion from '../../assests/Accordion'
import IndexedCards from '../../assests/IndexedCards'
import TH from '../../helpers/TextbookHelper'
import MH from '../../helpers/UserProfileHelper'
import AH from '../../helpers/AuthenticationHelper'
import init from '../../config/init'
import Palaces from '../../json/Palaces.json'
import SupportStarJSON from '../../json/SupStars.json'

const Textbook = props => {

    const [chartData, updateChartData] = useState(null)
    const [supStarData, updateSupStarData] = useState(null)
    const [profile, updateProfile] = useState(null)
    const [typeOfChart, updateTypeOfChart] = useState('natal')
    const [accordionArr, updateAccordionArr] = useState([])
    const [noSubMen, updateSubscriber] = useState(false)
    const [loadingTextbook, updateLoadingStatus] = useState(false)
    const [loadFeedback, updateLoadFeedback] = useState('')
    const TextbookWrapper = useRef(null)

    const dict = lang(props.preferences.lang)['textbook']

    let palacesArr = []

    const getSupstarData = async (key = 'tuoLuo', palaceStr, chartID, supportStars) => {
      const supStr = SupportStarJSON[key]['en']
      // console.log(`lucun is retrieved `, supStr)
      try
      {
        let supH = await TH.getSupStarsByPalace(supportStars[key].zodiac_sign.toLowerCase(), palaceStr.toLowerCase(), supStr, '',chartID)

        if(supH['element'] === "null" || supH['star'] === "null") return false
        supH['palace'] = palaceStr.toLowerCase()
        supH['zodiac'] = supportStars[key].zodiac_sign.toLowerCase()
        supH['emptyPalace'] = false
        // palacesArr[idx] = h
        return supH
      }
      catch(e)
      {
        return false
        // console.log(e)
      }
    }

    const concatSupStar = (palacesArr, idx, data) => {
      // console.log(data)
      if(palacesArr.hasOwnProperty(idx) && palacesArr[idx].hasOwnProperty('emptyPalace') && !palacesArr[idx]['emptyPalace'])
      {
        console.log("here")
        palacesArr[idx]['element'] = `${palacesArr[idx]['element']}，${data['element']}`
        palacesArr[idx]['star'] = `${palacesArr[idx]['star']}\\n${data['element']}\\n${data['star']}`
        return palacesArr
      }
      // console.log("not here")
      palacesArr[idx] = data
      // console.log(palacesArr)
      palacesArr[idx]['star'] = `${data['element']}\\n${data['star']}\\n`
      return palacesArr
    }

    const getData =  async(inputProps) => 
    {
      try
      {
        //if user is not logged in, do nothing 
        if (!props.isLoggedIn)
        {
          return
        }
        // to get the membership status, not used as it is controlled from the server side
        /*
        if(inputProps.location.hasOwnProperty('state') )
        {
          let membershipStatus= await MH.getMembershipState(AH.claims.sub) //TODO Need to replace the claims and sub with a stored uuid
          console.log(membershipStatus)
        }
        */
        // console.log(props)
        const data = props?.location?.state || null
 
        if(data.hasOwnProperty('chartData') && data['chartData'] !== null)
        {
          updateChartData(data.chartData)

          // console.log("GET DATA")
          // console.log(data.chartData)
          // parse the meaning of each house
          updateLoadingStatus(true)
          for (const [idx,house] of data['chartData'].entries())
          {
            // console.log('get each chart data')
            // console.log(idx)

            //TODO: IMPLEMENT Not Subscribed warning
            /*
            let h
            try{
              h =  await TH.getStarsByPalace(house.zodiac_str.toLowerCase(), house.palace_str.toLowerCase(), house.north_star_str, house.south_star_str)
            }
            catch(error)
            {
              if(error.toString().split(":")[2]=="NoSubMem")
              {
                console.log("Not subscribed")
              }
            }*/
            try
            {
              const chartID= props?.location?.state?.profile?.id || null
              // console.log(chartID)
              let h =  await TH.getStarsByPalace(house.zodiac_str.toLowerCase(), house.palace_str.toLowerCase(), house.north_star_str, house.south_star_str, chartID)
              if(h === 'NoSubMem') 
              {
                // console.log("no subscribed")
                //TODO comment out the bottom two to implement lock function
                // updateSubscriber(true)
                // break  //added break to prevent it from going further with no information
              }
              // console.log(h)
              if(h)
              {
                h['palace'] = house.palace_str.toLowerCase()
                h['zodiac'] = house.zodiac_str.toLowerCase()
                h['emptyPalace'] = false
                palacesArr[idx] = h
                continue
                // return
              }
              // console.log('check the opposite house for reference')

              // see if there are support stars that should be included
              let supportStars = [], gotSupportStar = false
              if(data?.fullStarData && data['fullStarData'] !== null)
              {
                supportStars = data?.fullStarData?.supportStarData
                try
                {
                  if(supportStars.hasOwnProperty('wenChang') && supportStars['wenChang']['position'] === idx)
                  {
                    gotSupportStar=true
                    const tlData = await getSupstarData('wenChang', house.palace_str, chartID, supportStars)
                    if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                  }
                  if(supportStars.hasOwnProperty('wenQu') && supportStars['wenQu']['position'] === idx)
                  {
                    gotSupportStar=true
                    const tlData = await getSupstarData('wenQu', house.palace_str, chartID, supportStars)
                    if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                  }
                  if(supportStars.hasOwnProperty('luCun') && supportStars['luCun']['position'] === idx)
                  {
                    gotSupportStar=true
                    const tlData = await getSupstarData('luCun', house.palace_str, chartID, supportStars)
                    if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                  }
                  if(supportStars.hasOwnProperty('qingYang') && supportStars['qingYang']['position'] === idx)
                  {
                    gotSupportStar=true
                    const tlData = await getSupstarData('qingYang', house.palace_str, chartID, supportStars)
                    if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                  }
                  if(supportStars.hasOwnProperty('tuoLuo') && supportStars['tuoLuo']['position'] === idx)
                  {
                    gotSupportStar=true
                    const tlData = await getSupstarData('tuoLuo', house.palace_str, chartID, supportStars)
                    if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                  }
                  if(supportStars.hasOwnProperty('huoXing') && supportStars['huoXing']['position'] === idx)
                  {
                    gotSupportStar=true
                    const tlData = await getSupstarData('huoXing', house.palace_str, chartID, supportStars)
                    if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                  }
                  if(supportStars.hasOwnProperty('lingXing') && supportStars['lingXing']['position'] === idx)
                  {
                    gotSupportStar=true
                    const tlData = await getSupstarData('lingXing', house.palace_str, chartID, supportStars)
                    if(tlData) palacesArr = concatSupStar(palacesArr,idx,tlData)
                  }

                  // if(supportStars.hasOwnProperty('wenChang') && supportStars['wenChang']['position'] === idx)
                  // {
                  //   const wcData = await getSupstarData('wenChang', house.palace_str, chartID, supportStars)
                  //   console.log(wcData)
                  //   try
                  //   {
                  //     palacesArr = concatSupStar(palacesArr,idx,wcData)
                  //   }
                  //   catch(e)
                  //   {
                  //     console.log(e)
                  //   }
                  //   // continue
                  // }
                }
                catch(e)
                {
                  console.log(e)
                }
                if(gotSupportStar)
                {
                  continue
                }
              }

              // console.log('support star data ', supportStars)

              // get stars from the opposite house
              if(idx + 6 < 12)
              {
                const altIdx = idx + 6
                const newHouse = data['chartData'][altIdx]
                // console.log(newHouse)
                let newH =  await TH.getStarsByPalace(house.zodiac_str.toLowerCase(), house.palace_str.toLowerCase(), newHouse.north_star_str, newHouse.south_star_str,chartID)
                if(newH)
                {
                  newH['emptyPalace'] = true
                  newH['palace'] = house.palace_str.toLowerCase()
                  newH['zodiac'] = house.zodiac_str.toLowerCase()
                  palacesArr[idx] = newH
                  // return
                  continue
                }
              }
              else
              {
                const altIdx = idx + 6 - 12
                const newHouse = data['chartData'][altIdx]
                // console.log(newHouse)
                let newH =  await TH.getStarsByPalace(house.zodiac_str.toLowerCase(), house.palace_str.toLowerCase(), newHouse.north_star_str, newHouse.south_star_str,chartID)
  
                if(newH)
                {
                  newH['emptyPalace'] = true
                  newH['palace'] = house.palace_str.toLowerCase()
                  newH['zodiac'] = house.zodiac_str.toLowerCase()
                  palacesArr[idx] = newH
                  // console.log(palacesArr)
                  // return
                  continue
                }
                // break
              }
              
            }
            catch(error)
            {
              // console.log("There is an error")
              updateLoadingStatus(false)
              updateLoadFeedback(dict['textbookFailedToLoad'])
              return
            }
            // console.log(h)
            //palacesArr[idx] = undefined
            //return

          }
          // console.log("PARSE PALACE")
          // console.log(palacesArr)
          parsePalaceMeaning()
          updateLoadingStatus(false) //finish loading from server
        }
        if(data.hasOwnProperty('profile') && data['profile'] !== null)
        {
          // console.log("Update profile")
          updateProfile(data.profile)
        }
        if(data.hasOwnProperty('typeOfChart') && data['typeOfChart'] !== null)
        {
          // console.log("Update type")
          updateTypeOfChart(data.typeOfChart)
        }

      }
      catch(e)
      {
        // console.log('error happened')
        // console.log(e)
      }

    }

    const parsePalaceMeaning = () => {
      let accArr = []
      // console.log("Update Accordion")
      if(palacesArr.length > 0)
      {
        try
        {
          
          let chartSequence = [], newPalaceArr = []
          Palaces.forEach((p,idx) => {
            chartSequence[idx] = p.id.toLowerCase()
          })
          palacesArr.forEach((palace, idx) => {
            const palaceId = Palaces.find(e => e['id'].toLowerCase() === palace.palace )['id'].toLowerCase()
            const newIdx = chartSequence.indexOf(palaceId)
            const palaceText = Palaces.find(e => e['id'].toLowerCase() === palace.palace )
            
            const accObj = {
              key: palace.zodiac,
              title: palaceText['tw'].replace(' ',''),
              zodiac: palace.zodiac,
              id: palace.palace,
              main: palace.element.replace(' ',''),
              support: palace.star !== 'Locked' ?  palace.star.replace(' ','') : <IonRow>
              <IonCol>
                <p style={{margin: 'auto'}}>{dict['subscribeFirst']}</p>
                <IonButton style={{ width: '15em', margin: 'auto', marginTop: '2em' }} color="zprimary"  onClick={() => props.history.push(init.router.subscribe.url)}>{dict['subscribe']}</IonButton>
              </IonCol>
              </IonRow>,
              extra: palace.emptyPalace ? dict['emptyPalace'] : '',
              extraComponent: <p style={{ position: 'absolute', right: '20px', top: 0, cursor: 'pointer' }} onClick={e => scrollToTop()}>{dict['backToTop']}</p>
            }
            newPalaceArr[newIdx] = accObj
            // accArr.push(accObj)
          })
          // console.log(newPalaceArr)
          updateAccordionArr(newPalaceArr)
        }
        catch(e)
        {
          // console.log(e)
        }
      }

    }

    const scrollToContent = (y = 0) => {
      // console.log(`should scroll to ${y}`)
      // console.log(TextbookWrapper)
      TextbookWrapper.current.scrollToPoint(0, y, 500)
    }

    const scrollToTop = () => {
      TextbookWrapper.current.scrollToTop(500)
    }
    // console.log("render")
    // console.log(props?.location?.state)

    useIonViewDidEnter(() => {
      // let inputProps={...props}
      // console.log("Ion View LifeCycle")
      // console.log(props?.location?.state)

      if(props?.location?.pathname=="/textbook") //call only on textbook page
      {
        // console.log("Should get data")
        getData(props);
      }
      else{  //remove the load bar when going to other pages
        // console.log("OH?")
        updateLoadingStatus(false)
      }
    }, [props?.location])

    // useEffect(()=>{

    //   let inputProps={...props};
    //   console.log(inputProps)
    //   if(props?.location?.pathname=="/textbook") //call only on textbook page
    //   {
    //     getData(inputProps);
    //   }
    //   else{  //remove the load bar when going to other pages
    //     updateLoadingStatus(false)
    //   }
    // }, [props.location,getData])

    // console.log(chartData)
    // console.log(accordionArr)



    if(!props.isLoggedIn)
    {
      // console.log()
      return (<IonPage>
        <IonHeader className='bitmap-texture'>
          <IonToolbar>
            <IonButtons slot="start" onClick={e => props.history.push(init.router.profile.url , { chartData: profile })}>
              <IonIcon src={require('../../images/Icon/Back.svg')} /><p className='back-btn-text'>{`上一頁`}</p>
            </IonButtons>
            <IonTitle className='ion-font-title'>{dict['title']}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='profile-folder-container'>
            <IonGrid>
            <IonRow>
              <IonCol>
                <IonImg style={{ width: '15em', margin: 'auto', marginTop: '2em' }} src={require('../../images/404_page.svg')} />
              </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <p  style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['loginFirst']}</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton style={{ width: '15em', margin: 'auto', marginTop: '2em' }} color="zprimary" expand="block" onClick={() => props.history.push(init.router.login.url)}>{dict['register']}</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
        </IonContent>

      </IonPage>)
    }

    if(noSubMen)
    {
      // console.log('this is not subsscriber')
      return (<IonPage>
        <IonHeader className='bitmap-texture'>
          <IonToolbar>
            <IonButtons slot="start" onClick={e => props.history.push(init.router.chart.url , { chartData: profile })}>
              <IonIcon src={require('../../images/Icon/Back.svg')} /><p className='back-btn-text'>{`上一頁`}</p>
            </IonButtons>
            <IonTitle className='ion-font-title'>{dict['title']}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='profile-folder-container'>
            <IonGrid>
            <IonRow>
              <IonCol>
                <IonImg style={{ width: '15em', margin: 'auto', marginTop: '2em' }} src={require('../../images/404_page.svg')} />
              </IonCol>
              </IonRow>
            
              <IonRow>
                <IonCol>
                  <p style={{ width: '15em', margin: 'auto', marginTop: '2em' }}>{dict['subscribeFirst']}</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton style={{ width: '15em', margin: 'auto', marginTop: '2em' }} color="zprimary"  onClick={() => props.history.push(init.router.subscribe.url)}>{dict['subscribe']}</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
        </IonContent>

      </IonPage>)
    }

    if(loadingTextbook)
    {
      return(
        <IonPage>
        <IonHeader className='bitmap-texture'>
          <IonToolbar>
            <IonButtons slot="start" onClick={e => props.history.push(init.router.chart.url , { chartData: profile })}>
              <IonIcon src={require('../../images/Icon/Back.svg')} /><p className='back-btn-text'>{`上一頁`}</p>
            </IonButtons>
            <IonTitle className='ion-font-title'>{dict['title']}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className='profile-folder-container'>
        
        <div className="textbook-container">
        <p style={{ marginLeft: '16px' }}>{dict['loading']}</p>
        </div>
        <div className='profile-background-img' style={{ backgroundImage: `url(${require('../../images/Logo.svg')})` }}></div>
        <IonLoading
          backdropDismiss
          // cssClass='my-custom-class'
          isOpen={true}
          message={dict['loading']}
        />
        </IonContent>
      </IonPage>
      )
    }

    if(loadFeedback !== '')
    {
      // console.log('this is not subsscriber')
      return (<IonPage>
        <IonHeader className='bitmap-texture'>
          <IonToolbar>
            <IonButtons slot="start" onClick={e => props.history.push(init.router.chart.url , { chartData: profile })}>
              <IonIcon src={require('../../images/Icon/Back.svg')} /><p className='back-btn-text'>{`上一頁`}</p>
            </IonButtons>
            <IonTitle className='ion-font-title'>{dict['title']}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='profile-folder-container'>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <p>{loadFeedback}</p>
                </IonCol>
              </IonRow>
            </IonGrid>
        </IonContent>

      </IonPage>)
    }

    return (
      (chartData === null || chartData.length < 0) ?
          <IonPage>
            <IonHeader className='bitmap-texture'>
              <IonToolbar>
                <IonButtons slot="start" onClick={e => props.history.push(init.router.chart.url , { chartData: profile })}>
                  <IonIcon src={require('../../images/Icon/Back.svg')} /><p className='back-btn-text'>{`上一頁`}</p>
                </IonButtons>
                <IonTitle className='ion-font-title'>{dict['title']}</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent className='profile-folder-container'>
            
            <div className="textbook-container">
            <p style={{ marginLeft: '16px' }}>{dict['loading']}</p>
            </div>
            <div className='profile-background-img' style={{ backgroundImage: `url(${require('../../images/Logo.svg')})` }}></div>
            <IonLoading
              backdropDismiss
              // cssClass='my-custom-class'
              isOpen={true}
              message={dict['loading']}
            />
            </IonContent>
          </IonPage>
        :
        <IonPage>
          <IonHeader className='bitmap-texture'>
            <IonToolbar>
              <IonButtons slot="start" onClick={e => props.history.push(init.router.chart.url , { chartData: profile })}>
                <IonIcon src={require('../../images/Icon/Back.svg')} /><p className='back-btn-text'>{`上一頁`}</p>
              </IonButtons>
              <IonTitle className='ion-font-title'>{dict['title']}</IonTitle>
            </IonToolbar>
          </IonHeader>
    
          <IonContent ref={TextbookWrapper} className='profile-folder-container' scrollEvents={true}>
          <IndexedCards cardsData={accordionArr || []} onSelect={scrollToContent} onDelete={() => console.log('delete')} />
          </IonContent>
        </IonPage>
    )

}

export default withIonLifeCycle(withPreloader(Textbook))